.dropzone {
  overflow: auto;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  @media (min-width: 1400px) {
    min-height: 200px;
  }
  max-height: 200px;
  padding: 0;
  &.dz-clickable {
    .dz-message {
      margin-top: 65px;
      * {
        @extend .text-muted;
      }
    }
  }
  .dz-preview {
    &.dz-image-preview,
    &.dz-file-preview {
      .dz-image {
        border-radius: $input-border-radius;
      }
    }
  }
}